import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/img7.jpg";
import { useTranslation } from "react-i18next";

const AboutSection = ({ aboutClass }) => {
  const { t } = useTranslation("about")
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">{t("aboutUs")}</h4>
              <h2 className="section__title">{t("1stHeader")}</h2>
              <p className="section__description">{t("1stText")}</p>

              <div className="about__section-item align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> {t("cityTransfer")}

                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> {t("fast&EasyBooking")}
                </p>
              </div>

              <div className="about__section-item  align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> {t("manyPickupLocations")}
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> {t("airportTransfer")}
                </p>
              </div>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100"  style={{"object-fit": "scale-down"}}/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
