import React, {useEffect, useState} from "react";
// import Saisirecar from "../components/UI/Saisirecar";import React from "react";
// import "../../styles/Saisirecar.css";
import {Container, Form, FormGroup} from "reactstrap";
import "../../styles/common-section.css";
import {useNavigate} from "react-router-dom";

import {useGetCategories} from "../../features/categories.queries";
import {useGetMarques} from "../../features/car.queries";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Typography, styled } from "@mui/material";
import { useGetLocations } from "../../features/locations.queries";
import ComboBox from "../ComboBox";
import { useTranslation } from "react-i18next";

const StyledDatePicker = styled(DateTimePicker)(({ theme }) => ({
  width: "100%",
  height: 40,

  // Targeting the specific MuiInputBase and MuiOutlinedInput classes
  ".MuiOutlinedInput-root": {
    borderColor: "rgba(0, 0, 0, 0.3) !important",
    height: 40,
  },

  // Targeting the label for the input
  ".MuiFormLabel-root": {
    color: "rgba(0, 0, 0, 0.3) !important",
  },

  // Styling the error state
  ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.3) !important",
  },

  // Styling the label itself
  "& label.MuiFormLabel-root": {
    backgroundColor: "white",
    paddingRight: 5,
  },

  // Hover effect
  "&:hover": {
    ".MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.8) !important",
    },
    ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.8) !important",
    },
  },
}));


const FilterCarsForm = ({style, onConfirm = () => null, defaults={}}) => {

    // const [pickUpPlace, setPickUpPlace] = useState(null);
    // const [returnPlace, setReturnPlace] = useState(null);
    const [category, setCategory] = useState();
    const [brand, setBrand] = useState();
    const [pickUpDate ,setPickUpDate] = useState(moment());
    const [returnDate, setReturnDate] = useState(moment(pickUpDate).add(1, "days"));
    const [rerenderCategory, setRerenderCategory] = useState(false)
    const [firstRender, setFirstRender] = useState(true)
    // const [samePickDrop, setSamePickDrop] = useState(true)
    const { t } = useTranslation("cars")

    const {data: categories = [], isLoading: isCategoriesLoading} = useGetCategories()
    const {data: brands = [], isLoading: isBrandsLoading} = useGetMarques()
    // const {data: places = [], isLoading: isPlacesLoading} = useGetLocations()

    useEffect(
      () => {

        const foundCategory = categories?.find(c => c._id === defaults.category_id)

        if (foundCategory) {

          if(firstRender) {

            setCategory({
              value: foundCategory._id,
              label: foundCategory.name
            })

            setRerenderCategory(true)

            if(category) {
              setFirstRender(false)
              setTimeout(
                () => {
                  setRerenderCategory(false);
                  submit()
                },
                400
              )

            }

          }
        }

      }, [categories, defaults, category]
    )

    const submit = () => {
        onConfirm({
          category_id: category?.value,
          brand_name: brand?.value,
          pickUpDate: pickUpDate?.format("x"),
          returnDate: returnDate?.format("x"),
          // pickUpPlace: pickUpPlace?.value,
          // returnPlace: samePickDrop ? pickUpPlace?.value : returnPlace?.value,
        })
    }

    useEffect(
      () => {
          setTimeout(
            () => {
              if(!firstRender || Object.keys(defaults).length === 0) {
                submit()
              }
            }, 
            400
          )
      }, []
    )

    return (
      <Form className="transparent-form" style={style && {...style}}>
          
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={(isCategoriesLoading || isBrandsLoading /*|| isPlacesLoading*/)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

            <Grid container spacing={1}>

              {/* <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <FormControlLabel 
                    control={<Checkbox defaultChecked={samePickDrop} onChange={() => setSamePickDrop(prev => !prev)}/>} 
                    label={t("samePlace")}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={1}>

                    <Grid item xs={samePickDrop ? 12 : 6}>
                        <ComboBox
                            label={samePickDrop ? t("depart&ReturnPlace") : t("departPlace")}
                            options={places.map(place => ({ value: place?._id, label: place?.name }))}
                            onSelect={setPickUpPlace}
                        />
                    </Grid>

                    {
                        !samePickDrop &&  
                            <Grid item xs={6}>
                                <ComboBox
                                    label={t("returnPlace")}
                                    options={places.map(place => ({ value: place?._id, label: place?.name }))}
                                    onSelect={setReturnPlace}
                                    defaultValue={samePickDrop && pickUpPlace}
                                />
                            </Grid>
                    }

                </Grid>
              </Grid> */}

              <Grid item xs={12} sm={3} md={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <StyledDatePicker
                    label={t("departDate")}
                    disablePast 
                    minutesStep = {30} 
                    ampm={false} 
                    skipDisabled 
                    format="DD/MM/YYYY HH:mm"
                    onChange={(newValue) => setPickUpDate(newValue)} 
                    value={pickUpDate}
                    error={false}
                    helperText={null}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <StyledDatePicker
                    label={t("returnDate")}
                    disablePast 
                    minutesStep = {30} 
                    ampm={false} 
                    skipDisabled 
                    format="DD/MM/YYYY HH:mm"
                    onChange={(newValue) => setReturnDate(newValue)} 
                    value={returnDate}
                    error={false}
                    helperText={null}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                {/* <StyledLabel>catégorie</StyledLabel> */}
                <ComboBox
                  key={`${rerenderCategory}`}
                  label={t("category")}
                  options={categories.map(category => ({ value: category?._id, label: category?.name }))}
                  onSelect={setCategory}
                  defaultValue={category}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                {/* <StyledLabel>marque</StyledLabel> */}
                <ComboBox
                  label={t("brand")}
                  options={brands.map(brand => ({ value: brand, label: brand }))}
                  onSelect={setBrand}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                  <Button
                    onClick={submit}
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: 'black',
                      // padding: '15px',
                      height: 40,
                      // flex: 1,
                      // marginTop: "21px",
                      "&:hover": {
                        backgroundColor: 'var(--main-color)',
                      }
                    }}
                  >
                    {t("search")}
                  </Button>
                </Box>
              </Grid>

            </Grid>

      </Form>
    );
};

export default FilterCarsForm;
