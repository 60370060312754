import { makeStyles } from '@mui/styles';
import 'react-phone-input-2/lib/material.css'
import PhoneInput from 'react-phone-input-2';


const useStyles = makeStyles({

    root: (err) => ({
        "& .special-label": {
            top: -9,
            left: 10,
            // fontSize: '0.7rem',
            borderRadius: 5,
            color: `${err ? "#D32F2F" : "rgba(0, 0, 0, 0.6)"} !important`,
            maxWidth: '80%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        "& input": {
            height: 40,
            width: "100% !important",
            borderColor: `${err ? '#D32F2F' : ''} !important`,
            fontSize: '14px !important'
        },

        "& input:focus": {
            borderColor: `${err ? "#D32F2F" : "#0d6efd"} !important`,
            boxShadow: `0 0 0 1px ${err ? "#D32F2F" : "#0d6efd"} !important`,
        },


        "&:has(input:focus) .special-label": {
            color: `${err ? "#D32F2F" : "#0d6efd"} !important`,
        }

    }),

})


export default function PhoneNumber(props) {

    const classes = useStyles(false)

    return <PhoneInput {...props} className={classes.root} />
}
