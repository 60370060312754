import axios from 'axios'


const URL_API = process.env.REACT_APP_SERVER_BASE_API+"/rental-car/client/v1";

// Create axios instance.
const axiosInstance = axios.create({
    baseURL: URL_API,
})




// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    async config => {
        // const token = await memoizedToken('token');
        config.headers = {
            // 'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

// Response interceptor for API calls
// axiosInstance.interceptors.response.use((response) => {
//     return response
// }, async function (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 403 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         const access_token = await refreshAccessToken();
//         axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//         return axiosApiInstance(originalRequest);
//     }
//     return Promise.reject(error);
// });


export default axiosInstance
