import React from "react";
import { Col } from "reactstrap";
import "../../styles/services-list.css";
import { useTranslation } from "react-i18next";
// import servicesData from "../../assets/data/serviceData";

const servicesData = [
  {
    id: 1,
    title: "cityTransfer",
    icon: "ri-map-pin-2-line",
    desc: "cityTransferDesc",
  },
  {
    id: 2,
    title: "24/7Assistance",
    icon: "ri-timer-flash-line",
    desc: "24/7AssistanceDesc",
  },
  {
    id: 3,
    title: "competitivePricing",
    icon: "ri-map-pin-line",
    desc: "competitivePricingDesc",
  },

];

const ServicesList = () => {
  const { t } = useTranslation("welcome");
  return (
    <>
      {servicesData.map((item) => (
        <ServiceItem item={item} key={item.id} t={t}/>
      ))}
    </>
  );
};

const ServiceItem = ({ item, t }) => (
  <Col lg="4" md="4" className="mb-3" >
    <div className="service__item" style={{minHeight: "296px"}}>
      <span className="mb-3 d">
        <i class={item.icon} />
      </span>

      <h6>{t(item.title)}</h6>
      <p className="section__description" style={{flex: 1}}>{t(item.desc)}</p>
    </div>
  </Col>
);

export default ServicesList;
