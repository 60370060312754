import { useState, useEffect } from "react";
import { Gb, Fr, Es } from "react-flags-select";
import {
    Backdrop,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
} from "@mui/material";
// import Loading from "./loading/loading";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const languages = [
    { id: 0, name: "fr", icon: <Fr /> },
    { id: 1, name: "en", icon: <Gb /> },
    { id: 2, name: "es", icon: <Es /> },
];

function LanguageSwitcher({
    containerStyle = {},
    selectStyle = {},
    itemStyle = {},
    fullWidth = true,
}) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState(Cookies.get("language") || "fr");

    useEffect(() => {
        const loadTranslations = async () => {
            if (language) {
                setLoading(true);
                const namespaces = i18n.options.ns;

                await Promise.all(
                    namespaces.map(async (namespace) => {
                        try {
                            if (!i18n.getResourceBundle(language, namespace)) {
                                const response = await fetch(`/locales/${language}/${namespace}.json`);
                                const translations = await response.json();
                                await i18n.addResourceBundle(language, namespace, translations);
                                console.log(`Translations for ${language}/${namespace} loaded successfully.`);
                            } else {
                                console.log(`Translations for ${language}/${namespace} already loaded.`);
                            }
                        } catch (error) {
                            console.error(`Failed to load translations for ${language}/${namespace}:`, error);
                        }
                    })
                );

                console.log("All namespaces loaded for the selected language.");
                await i18n.changeLanguage(language); // Wait for the language change
                Cookies.set("language", language);
                setLoading(false);
            }
        };

        loadTranslations();
    }, [language, location.pathname, i18n]);
    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    background: "rgb(0 0 0 / 65%)",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <FormControl style={{ ...containerStyle }}>
                <Select
                    style={{ ...selectStyle }}
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                >
                    {languages.map((l) => (
                        <MenuItem
                            style={{ ...itemStyle }}
                            key={l.id}
                            value={l.name}
                        >
                            {l.icon}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}

export default LanguageSwitcher;
