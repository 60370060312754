import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {  Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/CarInfo.css";
import Image1 from "../../assets/all-images/imageforcards/Image1.jpg";
import Image2 from "../../assets/all-images/imageforcards/Image2.jpg";
import Image3 from "../../assets/all-images/imageforcards/Image3.jpg";
import { useGetPrimaryCategories} from "../../features/categories.queries";
import { useTranslation } from 'react-i18next';



function Carinfo() {

  const {data = []} = useGetPrimaryCategories()
  const { t } = useTranslation("welcome");

  return (
    <div className="row row-cols-1 row-cols-md-3 g-4">
  <div className="col">
  <Link className="lin" to={`/cars?category_id=${data.find(d=> d.slug === "4x4")?._id}`}>
    <div className="card">
      <img src={Image1 }className="card-img-top" alt=""  style={{"object-fit": "scale-down"}} />
      <div className="card-body">

            <h2 className="card-title">{data.find(d=> d.slug === "4x4")?.name} </h2>


        <p className="card-text">{t("card1")}</p>

        <button className='card-info'>{t("seeMore")}</button>

      </div>
    </div>
    </Link>
  </div>
  <div className="col">
  <Link className="lin" to={`/cars?category_id=${data.find(d=> d.slug === "berline")?._id}`}>
    <div className="card">
      <img src={Image2} className="card-img-top" alt="..."   style={{"object-fit": "scale-down"}} />
      <div className="card-body">
      
        <h2 className="card-title">{data.find(d=> d.slug === "berline")?.name}</h2>
        <p className="card-text">{t("card2")}</p>

        <button className='card-info'>{t("seeMore")}</button>

      </div>
    </div>
    </Link>
  </div>
  <div className="col">
  <Link className="lin" to={`/cars?category_id=${data.find(d=> d.slug === "citadine")?._id}`}>
    <div className="card">
      <img src={Image3} className="card-img-top" alt="..."   style={{"object-fit": "scale-down"}} />
      <div className="card-body">
      
        <h2 className="card-title">{data.find(d=> d.slug === "citadine")?.name}</h2>
        <p className="card-text">{t("card3")}</p>

        <button className='card-info'>{t("seeMore")}</button>

      </div>
    </div>
    </Link>
  </div>

</div>




  );
}

export default Carinfo;
