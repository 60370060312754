import React, { useRef } from "react";

import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";
// import SearchBar from "./SearchBar";

const navLinks = [
  {
    path: "/home",
    display: "home",
  },
  {
    path: "/about",
    display: "about",
  },
  {
    path: "/cars",
    display: "cars",
  },
  {
    path: "/contact",
    display: "contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);
  const { t } = useTranslation("common");
  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  return (
    <header className="header">
      {/* ============ header top ============ */}


      {/* ========== main navigation =========== */}

      <div className="main__navbar">
        <Container style={{display: "flex", justifyContent: "space-between"}}>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i class="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                    // reloadDocument
                  >
                    {t(item.display)}
                  </NavLink>
                ))}
              </div>
            </div>

            {/*<div className="nav__right">*/}
            {/*  <div className="search__box">*/}
            {/*    <input type="text" placeholder="Search" />*/}
            {/*    <span>*/}
            {/*      <i class="ri-search-line"></i>*/}
            {/*    </span>*/}
            {/*    /!* {SearchBar} *!/*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>

          <LanguageSwitcher
            // containerStyle={{}}
            selectStyle={{
              backgroundColor: "white",
              height: 37,
              width: 64,
            }}
          />

        </Container>
      </div>
    </header>
  );
};

export default Header;
