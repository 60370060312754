import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import FilterCarsForm from "../components/UI/FilterCarsForm";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
import SearchBar from "./SearchBar";
import "../styles/CarListing.css";
import { useGetCars } from "../features/car.queries";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, Box, CircularProgress, TextField, Typography } from "@mui/material";
import HeroSlider from "../components/UI/HeroSlider";
import SortIcon from '@mui/icons-material/Sort';
import SearchIcon from '@mui/icons-material/Search';
import ComboBox from "../components/ComboBox";
import { useTranslation } from "react-i18next";


const filterUniqueCars = (cars) => {

  const uniqueCars = {};

  cars.forEach(car => {
    // Create a key based on the car's main characteristics
    const key = `${car.marque}_${car.model}_${car.fuelType}_${car.gearbox}`;
    
    // If this key doesn't exist in uniqueCars, add it
    // If it does exist, only replace it if the new car has a lower price
    if (!uniqueCars[key] || car.pricePerDay < uniqueCars[key].pricePerDay) uniqueCars[key] = car;
  });

  // Convert the object back to an array
  return Object.values(uniqueCars);
};

const CarListing = () => {
  const location = useLocation();

  const params = Object.fromEntries(
    new URLSearchParams(location.search)
    .entries()
  );

  const myRef = useRef(null);

  const [cars, setCars] = useState([]);
  const [filter, setFilter] = useState({})
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  
  const { t } = useTranslation("cars")
  // const { isLoading, data = [], refetch } = useGetCars(params);
  const { isLoading, data = [], refetch } = useGetCars({...filter});

  useEffect(() => {
   
    // console.log(data[0])
    // console.log({
    //     name: data[0]?.name,
    //     availableAfter : data[0]?.availableAfter,
    //     availableFrom : data[0]?.availableFrom,
    //     availableTo : data[0]?.availableTo,
    // })

    setCars(filterUniqueCars(data));
  }, [data]);

  useEffect(() => {
    setFilter(params)
  }, [JSON.stringify(params)])


  const handleInputChange = (e) => {
      const value = e.target.value;
      setSearchTerm(value)
      const searchResults = data?.filter(booking => {
          return Object.values(booking).some((element) => typeof element === 'string' && element?.toLowerCase()?.includes(value.toLowerCase()));
      });

      // console.log(":::::", searchResults)
      setCars(searchResults);


  };


  const scrollToElement = () => {
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const sort = (value) => {
    // const value = e.target.value;
    if (value === "low") {
      sortLowHigh();
    } else {
      sortHighLow();
    }
  };

  const sortLowHigh = () => {
    const d = cars.sort((p1, p2) =>
      p1.pricePerDay > p2.pricePerDay
        ? 1
        : p1.pricePerDay < p2.pricePerDay
        ? -1
        : 0
    );
    setCars([...d]);
  };
  const sortHighLow = () => {
    const d = cars.sort((p1, p2) =>
      p1.pricePerDay < p2.pricePerDay
        ? 1
        : p1.pricePerDay > p2.pricePerDay
        ? -1
        : 0
    );
    setCars([...d]);
  };

  return (
    <Helmet title="Cars">
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <HeroSlider /> */}

      {/* <CommonSection
        title=""
        category_id={params.get("category_id")}
        brand_name={params.get("brand_name")}
        return_Date={params.get("returnDate")}
        pickUp_Date={params.get("pickUpDate")}
      /> */}

      <section className="sect" ref={myRef} style={{minHeight: "calc(100vh - 65px)"}}>
        <Container>
          <Row>
      {/* <div style={{
        backgroundColor: "#dee2e645", 
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "95px 0 90px",
      }}> */}
          <FilterCarsForm 
            key={JSON.stringify(params)}
            onConfirm={setFilter}
            style={{marginTop: 15, marginBottom: 30, width: "100%"}}
            defaults={filter}
          />
      {/* </div> */}
          </Row>
          <Row>
            <Col lg="12" style={{
              display: 'flex',
              justifyContent: "space-between",
              width: '100%',
              margin: "35px 0",
              flexWrap: 'wrap',
            }}>

              <Box sx={{display: 'flex', justifyContent: 'space-between', minWidth: "174px", mb:1}}>

                {/* <Typography variant="body2" sx={{fontSize: 20, mr: 3, textWrap: "nowrap"}}>
                  <SortIcon sx={{fontSize: 35, mr:1}}/>
                  Trier par prix
                </Typography> */}

                <ComboBox
                  options={[
                    {value: "low", label: t("lowToHigh")},
                    {value: "high", label: t("highToLow")},
                  ]}
                  label={<><SortIcon sx={{fontSize: 20, mr:1}}/>{t("sortByPrice")}</>}
                  // size="small"
                  disableClearable={true}
                  onSelect={(val) => sort(val?.value)}
                />

              </Box>

              {/* <SearchBar cars={data} setCars={setCars} /> */}
              <TextField
                size="small"
                label={<><SearchIcon sx={{fontSize: 20}}/>{t("searchCar")}</>}
                variant={"outlined"}
                // fullWidth
                value={searchTerm}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Col>

            {cars.map((item) => (
              <CarItem
                  item={item}
                  return_Date={filter?.returnDate}
                  pickUp_Date={filter?.pickUpDate}
                  pickUp_Place={filter?.pickUpPlace}
                  return_Place={filter?.returnPlace}
                  key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarListing;
