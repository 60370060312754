import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox({
    options = [],
    label,
    onSelect = () => { },
    size="small",
    variant="outlined",
    defaultValue,
    disableClearable,
    required,
    ...props
}) {

    const [value, setValue] = useState()
    const [localDefaultValue, setLocalDefaultValue] = useState(defaultValue?.value || defaultValue)

    useEffect(
        () => {
            if (localDefaultValue) {
                setValue(options?.find(opt => opt.value == localDefaultValue))
                setLocalDefaultValue(null)
            }
        }, [options, localDefaultValue]
    )

    const textFieldBgClr = {
        '& .MuiFilledInput-root': {
            backgroundColor: 'white'
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: 'white'
        }
    }
    
    return (
        <Autocomplete
            key={`val-${value?.value}`}
            {...props}
            size={size}
            fullWidth
            options={options}
            value={value}
            disableClearable={disableClearable}
            onChange={(event, newValue) => {
                setValue(newValue);
                onSelect(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size={size}
                    label={label}
                    variant={variant}
                    sx={{...textFieldBgClr}}
                    fullWidth
                    value={value}
                    required={!!required}
                    InputLabelProps={{ shrink: true }}
                    // error={Boolean(errors["forfait"])}
                    // helperText={errors["forfait"] ? errors["forfait"].message : ""}
                />
            )}
        />
    );
}
