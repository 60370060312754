import Layout from "./components/Layout/Layout";
import {Hydrate, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import client from "./lib/client";
import './i18n';

function App() {

    return <QueryClientProvider client={client}>
            {/* <script
                src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=geometry,places`}
            ></script> */}

            <Layout/>
        <ReactQueryDevtools initialIsOpen={false}/>
    </QueryClientProvider>
}

export default App;

