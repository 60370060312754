import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Pages/Home";
import CarListing from "../Pages/CarListing";
import CarListing2 from "../Pages/CarListing2";
import CarDetails from "../Pages/CarDetails";
import CarDetails2 from "../Pages/CarDetails2"
import Contact from "../Pages/Contact";
import About from "../Pages/About";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
// import CarListing from "../pages/CarListing";
// import CarDetails from "../pages/CarDetails";
// import Blog from "../pages/Blog";
// import BlogDetails from "../pages/BlogDetails";
// import NotFound from "../pages/NotFound";
// import Contact from "../pages/Contact";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/cars" element={<CarListing />} />
      {/*<Route path="/CarListing2" element={<CarListing2 />} />*/}
      <Route path="/cars/:slug" element={<CarDetails />} />
      {/*<Route path="/carss/:slug" element={<CarDetails2 />} />*/}
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      {/* <Route path="/cars" element={<CarListing />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default Routers;
