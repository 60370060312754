import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import Common from "../components/UI/common";

import "../styles/contact.css";
import { useTranslation } from "react-i18next";

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const { t } = useTranslation("contact")
  return (
    <Helmet title="Contact  ">
      <Common  title={t("contactUs")} />
      <section className="sect" >
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h3 className="fw-bold mb-4">{t("getInTouch")}</h3>

              <Form>
                <FormGroup className="contact__form">
                  <Input placeholder={t("name")} type="text" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder={t("email")} type="email" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input 
                    type="textarea"
                    rows="3"
                    placeholder={t("message")}
                  />

                </FormGroup>

                <button className=" contact__btn" type="submit">
                  {t("sendMessage")}
                </button>
              </Form>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h3 className="fw-bold">{t("contactInformation")}</h3>
                <p className= "p section__description mb-0">
                  Marrakech-Morocco
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h4 className="fs-6 mb-0">{t("phone")}:</h4>
                  <p className="p section__description mb-0">+212628275490</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h4 className="mb-0 fs-6">{t("email")}:</h4>
                  <p className="p section__description mb-0">Car@gmail.com</p>
                </div>

                <h3 className="fw-bold mt-4">{t("followUs")}</h3>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                    >
                      <i class={item.icon}></i>
                    </Link>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
