import {useFetch} from "../lib/reactQuery";
import {pathToUrl} from "../lib/router";
import {apiRoutes} from "./constant.routes";

export const useGetCars = (params) => useFetch(pathToUrl(apiRoutes.cars, {params}), params, {   display: !params});

export const useGetCar = (id) => useFetch(pathToUrl(apiRoutes.car,{id}),undefined,{
    display: !id
});
export const useGetMarques = (params) => useFetch(pathToUrl(apiRoutes.marques), params);
