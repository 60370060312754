import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
    const { t } = useTranslation("privacy");

    return (
        <Box
            sx={{
                p: 5,
                "& p": { pl: 2 },
                "& ul": { listStyle: "circle" },
            }}
        >
            <h1>{t("PrivacyPolicy")}</h1>
            <p>{t("EffectiveDate")}: 01/03/2020</p>

            <p>{t("welcomeParagraph")}</p>

            <h2>{t("InformationWeCollect")}</h2>
            <p>{t("InformationWeCollectIntro")}</p>
            <ul>
                <li>{t("PersonalInformation")}</li>
                <li>{t("UsageData")}</li>
                <li>{t("VehicleInformation")}</li>
            </ul>

            <h2>{t("HowWeUseYourInformation")}</h2>
            <p>{t("HowWeUseYourInformationIntro")}</p>
            <ul>
                <li>{t("ToProvideAndImproveServices")}</li>
                <li>{t("ToCommunicate")}</li>
                <li>{t("ToEnsureCompliance")}</li>
            </ul>

            <h2>{t("HowWeShareYourInformation")}</h2>
            <p>{t("HowWeShareYourInformationIntro")}</p>
            <ul>
                <li>{t("ThirdPartyServiceProviders")}</li>
                <li>{t("LegalAuthorities")}</li>
            </ul>

            <h2>{t("DataSecurity")}</h2>
            <p>{t("DataSecurityDescription")}</p>

            <h2>{t("YourChoices")}</h2>
            <p>{t("YourChoicesIntro")}</p>
            <ul>
                <li>{t("Access")}</li>
                <li>{t("Correction")}</li>
                <li>{t("Deletion")}</li>
            </ul>

            <h2>{t("ChangesToThisPrivacyPolicy")}</h2>
            <p>{t("ChangesDescription")}</p>

            <h2>{t("ContactUs")}</h2>
            <p>{t("ContactDescription")}</p>
            <p>{t("Email")}: zakaria@albacar.ma</p>
            <p>{t("Phone")}: +212661849212</p>
            <p>{t("Address")}: Marrakech - Maroc</p>
        </Box>
    );
};

export default PrivacyPolicy;
