import React from "react";

import CommonSection from "../components/UI/common";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
// import BecomeDriverSection from "../components/UI/BecomeDriverSection";

import driveImg from "../assets/all-images/car1.jpg";
// import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation("about")
  return (
    <Helmet title="About">
      <CommonSection title={t("aboutUs")} />
      <AboutSection aboutClass="aboutPage" />

      <section className=" about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" style={{"object-fit": "scale-down"}}   />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">{t("2ndHeader")}</h2>

             

                <p className="section__description">{t("2ndText")}</p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">{t("help")}</h6>
                    <h4>+212661849212</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <BecomeDriverSection /> */}

      {/* <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section> */}
    </Helmet>
  );
};

export default About;
