import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import { useTranslation } from "react-i18next";
import { useGetPrimaryCategories } from "../../features/categories.queries";

const quickLinks = [
  {
    path: "/about",
    display: "about",
  },

  {
    path: "/privacy",
    display: "privacyPolicy",
  },

  {
    path: "/cars",
    display: "carListing",
  },



  {
    path: "/contact",
    display: "contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const { t } = useTranslation("common");
  const {data = []} = useGetPrimaryCategories()
  
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h5>
                <Link  to="/home" className=" lon d-flex align-items-center gap-2">
                  <i class="ri-car-line"></i>
                  <span>
                    {t("rentCarService")}
                  </span>
                </Link>
              </h5>
            </div>
            {/* <p className="footer__logo-content">{t("serviceText")}</p> */}
            {data.map((item, index) => (
              <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                <Link to={`/cars?category_id=${item?._id}`}>{t("rentCarMar", {category: item?.name})}</Link>
              </ListGroupItem>
            ))}
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">{t("quickLinks")}</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{t(item.display)}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
                  
          <Col lg="3" md="4" sm="6">
            <div className="col-sm-12">
              <h5 className="footer__link-title mb-4">{t("headOffice")}</h5>
              <p className="office__info">marrakech - maroc</p>
              <p className="office__info">{t("phone")}: +212661849212</p>

              <p className="office__info">{t("email")}: zakaria@albacar.ma</p>

              <p className="office__info">{t("officeTime")}: 10am - 7pm</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">{t("newsletter")}</h5>
              <p className="section__des">{t("subscribe")}</p>
              <div className="newsletter">
                <input type="email" placeholder={t("email")} />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1p-3 mb-2 bg-dark text-white">
                <i class="ri-copyright-line"></i>{t("copyright", {year})}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
