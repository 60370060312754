import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(Backend) // Load translations from the server or local files
    .use(LanguageDetector) // Detects user language
    .use(initReactI18next) // Integrates i18next with React
    .init({
        fallbackLng: "fr", // Default language
        // debug: true,
        locales: ["en", "fr", "es"],
        ns: ["common", "welcome", "cars", "contact", "about"],
        defaultNS: "common", // Default namespace
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json", // Path to the translation files
        },
    });

export default i18n;
