import React, {useState} from "react";
// import Saisirecar from "../components/UI/Saisirecar";import React from "react";
// import "../../styles/Saisirecar.css";
import {Container, Form, FormGroup} from "reactstrap";
import "../../styles/common-section.css";
import {useNavigate} from "react-router-dom";

import {useGetCategories} from "../../features/categories.queries";
import {useGetMarques} from "../../features/car.queries";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { styled } from "@mui/material";

const StyledDatePicker = styled(DateTimePicker)(({theme}) => ({
    width : "100%",
    "@media screen and (min-width: 668px)": {
      height : "40px",
    },
    border : "none",
    backgroundColor : "#FFF",
    borderRadius : "0",
    height : "35px", 
    "&:hover" : {
      border : "none",
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border : "none",
        },
        '&:hover fieldset': {
          border : "none",
        },
        '&.Mui-focused fieldset': {
          border : "none",
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border : "none",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border : "none",
    },
    '.MuiFormLabel-root': {
      color : "#7c8a97",
    },
    '.MuiInputBase-root' : {
      height : "40px", 
      color : "#7c8a97", 
      ".MuiInputAdornment-root button" : {
        color : "#7c8a97"
      }, 
      "& input::placeholder" : {
        color : "#7c8a97",
        opacity : "1"
      }
    }
  }))

const CommonSection = ({title, category_id, brand_name, pickUp_Date, return_Date}) => {
    const [category, setCategory] = useState(category_id);
    const [brand, setBrand] = useState(brand_name);
    const [pickUpDate ,setPickUpDate] = useState(pickUp_Date ? moment(parseInt(pickUp_Date)) : moment());
    const [returnDate, setReturnDate] = useState(return_Date ? moment(parseInt(return_Date)) : moment().add(1, "days"));
    const {data = []} = useGetCategories()
    const {data: brands = []} = useGetMarques()
    const navigate = useNavigate();
  
    const submit = () => {

        navigate(
            `/cars?${category ? "category_id=" + category : ""}${brand ? "&brand_name=" + brand : ""}${pickUpDate ? "&pickUpDate=" + moment(pickUpDate).format("x") : ""}${returnDate ? "&returnDate=" + moment(returnDate).format("x") : ""} `,
            {
                replace: false,
            }
        );
        setTimeout(() => {
            window.location.reload()
        }, 100)
    }

    return (
        <section className="common__section ">
            <Container className="text-center">

                <Form className="transparent-form">
                    <h1 className="text-light">{title}</h1>
                    <div className="inputs_holder">
                        <div>
                            <label>Pickup date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <StyledDatePicker 
                                    disablePast 
                                    minutesStep = {30} 
                                    ampm={false} 
                                    skipDisabled 
                                    format="DD/MM/YYYY HH:mm"
                                    onChange={(newValue) => setPickUpDate(newValue)} 
                                    onAccept = {(newValue) => setReturnDate(moment(newValue).add(1, "days"))}
                                    value={moment(pickUpDate)}
                                    />
                            </LocalizationProvider>
                            {/* <input type="datetime-local" placeholder="Journe" required

                                   min={moment().format("YYYY-MM-DDTHH:mm")}
                                   onChange={(e) => setPickUpDate(e.target.value)} value={pickUpDate}/> */}

                        </div>

                        <div>
                            <label>Drop-off date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <StyledDatePicker 
                                    disablePast 
                                    minutesStep = {30} 
                                    ampm={false} 
                                    skipDisabled 
                                    format="DD/MM/YYYY HH:mm"
                                    onChange={(newValue) => setReturnDate(newValue)} 
                                    value={moment(returnDate)}
                                    />
                            </LocalizationProvider>
                            {/* <input type="datetime-local"

                                   min={moment().format("YYYY-MM-DDTHH:mm")}
                                   placeholder="Journe" onChange={(e) => setReturnDate(e.target.value)}
                                   value={returnDate} required/> */}
                        </div>

                        <div>
                              <label>Category</label>
                            <select value={category} onChange={(event) => {
                                setCategory(event.target.value === "null" ? undefined : event.target.value)
                            }}>
                                <option value={"null"}>Select Category</option>
                                {
                                    data.map(cat => <option value={cat?._id}>{cat?.name}</option>)
                                }

                            </select>

                        </div>

                        {/*<FormGroup className="form__group">*/}
                        {/*    <input type="date" placeholder="Journey date" onChange={}/>*/}
                        {/*</FormGroup>*/}

                        <div>
                              <label>Brand</label>
                            <select value={brand} onChange={(event) => {
                                setBrand(event.target.value === "null" ? undefined : event.target.value)
                            }}>
                                <option value={"null"}>Select Brand</option>
                                {
                                    brands.map(b => <option value={b}>{b}</option>)
                                }

                            </select>

                        </div>
                    </div>
                        
                    <div className="button_holder">
                        <button type="button" onClick={submit}>
                            Confirm
                        </button>
                    </div>

                </Form>

            </Container>
        </section>
    );
};

export default CommonSection;
