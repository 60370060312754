import {useInfiniteQuery, useMutation, useQuery, useQueryClient, UseQueryOptions} from "@tanstack/react-query";
import axiosInstance from "./axios";

export const fetcher = async ({
                                     queryKey,
                                     pageParam
                                 }) => {
    const [url, params] = queryKey;
    const res = await axiosInstance.get(url, { params });

    return res.data;
};

export const useLoadMore = (url, params) => {
    return useInfiniteQuery(
        [url, params],
        ({queryKey, pageParam = 1}) => fetcher({queryKey, pageParam}),
        {
            getPreviousPageParam: (firstPage) => firstPage.previousId ?? false,
            getNextPageParam: (lastPage) => {
                return lastPage.nextId ?? false;
            }
        }
    );
};

export const usePrefetch = (url , params) => {
    const queryClient = useQueryClient();

    return () => {
        if (!url) {
            return;
        }

        queryClient.prefetchQuery(
            [url, params],
            ({ queryKey }) => fetcher({ queryKey })
        );
    };
};

export const useFetch = (
    url,
    params,
    config
) => {
    return useQuery(
        {
            queryKey:[url, params].filter(elem => !!elem ),
            queryFn:({queryKey}) => fetcher({queryKey}),
            enabled: !!url,
            ...config
        }
    );
};

const useGenericMutation = (
    func,
    url,
    params,
    updater,
    onSettled = ()=>null,
    onSuccess = ()=>null,
) => {
    const queryClient = useQueryClient();

    return useMutation(func, {
        onMutate: async (data, variables, context) => {

        },
        onSuccess: (result, variables, context) => {

            onSuccess(result.data,variables, context,queryClient)

           },
        onError: (err, _, context) => {
            queryClient.setQueryData([url, params], context);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries([url, params]);
            onSettled();
        }
    });
};

export const useDelete = (
    url,
    params,
    updater,
    onSettled,
    onSuccess
) => {
    return useGenericMutation(
        (id) => axiosInstance.delete(`${url}/${id}`),
        url,
        params,
        updater,
        onSettled,
        onSuccess
    );
};

export const usePost =(
    url,
    params,
    updater,
    onSettled,
    onSuccess,
    onError,
) => {
    return useGenericMutation(
        (data) => axiosInstance.post(url, data),
        url,
        params,
        updater,
        onSettled,
        onSuccess,
        onError
    );
};

export const useUpdate = (
    url,
    params,
    updater,
    onSettled,
    onSuccess,
    onError,
) => {
    return useGenericMutation(
        (data) => axiosInstance.patch(url, data),
        url,
        params,
        updater,
        onSettled,
        onSuccess,
        onError,
    );
};
