import React, {useState} from "react";
import "../../styles/find-car-form.css";
import {Form, FormGroup} from "reactstrap";
import {useGetLocations} from "../../features/locations.queries";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { styled } from "@mui/material";


const StyledDatePicker = styled(DateTimePicker)(({theme}) => ({
    width : "100%",
    "@media screen and (min-width: 668px)": {
      height : "40px",
    },
    border : "none",
    backgroundColor : "#FFF",
    borderRadius : "0",
    height : "35px", 
    "&:hover" : {
      border : "none",
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border : "none",
        },
        '&:hover fieldset': {
          border : "none",
        },
        '&.Mui-focused fieldset': {
          border : "none",
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border : "none",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border : "none",
    },
    '.MuiFormLabel-root': {
      color : "#7c8a97",
    },
    '.MuiInputBase-root' : {
      height : "40px", 
      color : "#7c8a97", 
      ".MuiInputAdornment-root button" : {
        color : "#7c8a97"
      }, 
      "& input::placeholder" : {
        color : "#7c8a97",
        opacity : "1"
      }
    }
  }))
const FindCarForm = ({history}) => {

    const {data = [], isLoading} = useGetLocations()

    const [pickUpPlace, setPickUpPlace] = useState(null);
    const [returnPlace, setReturnPlace] = useState(null);
    const [pickUpDate, setPickUpDate] = useState(null);
    const [returnDate, setReturnDate] = useState(null);

    const [error, setError] = useState(false);


    const navigate = useNavigate();

    const submit = () => {
        setError(false)

        if (pickUpPlace && returnPlace && pickUpDate && returnDate) {
            navigate(
                `/cars?${pickUpPlace ? "pickUpPlace=" + pickUpPlace : ""}${returnPlace ? "&returnPlace=" + returnPlace : ""}${pickUpDate ? "&pickUpDate=" + moment(pickUpDate).format("x") : ""}${returnDate ? "&returnDate=" + moment(returnDate).format("x") : ""} `,
                {
                    replace: false,
                }
            );
            setTimeout(() => {
                window.location.reload()
            }, 100)
        } else {
            setError(true)
        }
    }
    return (

        <Form className="form transparent-form">
            <h3>find your best car rental</h3>
            {error && <h6 style={{color: "red"}}>** all fields are required</h6>}

            <div className="inputs_holder">
                <div>
                    <label>Pickup place</label>

                    <select value={pickUpPlace} onChange={(event) => {
                        setPickUpPlace(event.target.value === "null" ? undefined : event.target.value)
                    }}>
                        <option value={"null"}>Choose a place</option>
                        {
                            data.map(cat => <option value={cat?._id}>{cat?.name}</option>)
                        }

                    </select>

                </div>

                <div>
                    <label>Drop-off place</label>

                    <select value={returnPlace} onChange={(event) => {
                        setReturnPlace(event.target.value === "null" ? undefined : event.target.value)
                    }}>
                        <option value={"null"}>Choose a place</option>
                        {
                            data.map(cat => <option value={cat?._id}>{cat?.name}</option>)
                        }

                    </select>

                </div>

                <div>
                    <label>Pickup date</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledDatePicker 
                            disablePast 
                            minutesStep = {30} 
                            ampm={false} 
                            skipDisabled 
                            format="DD/MM/YYYY HH:mm"
                            onChange={(newValue) => setPickUpDate(newValue)} 
                            onAccept = {(newValue) => setReturnDate(moment(newValue).add(1, "days"))}
                            value={pickUpDate}
                            />
                    </LocalizationProvider>
                    {/* <input type="datetime-local" placeholder="Journe" required

                           min={moment().format("YYYY-MM-DDTHH:mm")}
                           onChange={(e) => setPickUpDate(e.target.value)} value={pickUpDate}/> */}

                </div>
            
                <div>
                    <label>Drop-off date</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledDatePicker 
                            disablePast 
                            minutesStep = {30} 
                            ampm={false} 
                            skipDisabled 
                            format="DD/MM/YYYY HH:mm"
                            onChange={(newValue) => setReturnDate(newValue)} 
                            value={returnDate}
                            />
                    </LocalizationProvider>
                    {/* <input type="datetime-local" placeholder="Journe"

                           min={moment().format("YYYY-MM-DDTHH:mm")}
                           onChange={(e) => setReturnDate(e.target.value)}
                           value={returnDate} required/> */}
                </div>
            </div>
               
            <div className="button_holder">
              <button type="button" onClick={submit}>
                Find Car
              </button>
            </div>
              
                
        </Form>
    );
};

export default FindCarForm;
